import { params } from '../tools/constants';

export const post = async function (url, token, body) {
    try {
        let response = await fetch(params().apiUri + url, {
            method: 'POST',
            mode: "cors",
            headers: {
                'Accept': 'application/x-www-form-urlencoded',
                'Origin': 'http://localhost:3000',
                'Content-Type': 'application/json',
                'x-access-token': token
            },
            body: JSON.stringify(body),
        });

        return response;
    } catch (error) {

    };
}

export const postBinary = async function (url, token, body) {
    try {
        let response = await fetch(params().apiUri + url, {
            method: 'POST',
            mode: "cors",
            headers: {
                'Accept': 'application/x-www-form-urlencoded',
                'Origin': 'http://localhost:3000',
                'x-access-token': token
            },
            body: body,
        });

        return response;
    } catch (error) {

    };
}

export const put = async function (url, token, body) {
    try {
        let response = await fetch(params().apiUri + url, {
            method: 'PUT',
            mode: "cors",
            headers: {
                'Accept': 'application/x-www-form-urlencoded',
                'Origin': 'http://localhost:3000',
                'Content-Type': 'application/json',
                'x-access-token': token
            },
            body: JSON.stringify(body),
        });

        return response;
    } catch (error) {

    };
}

export const get = async function (url, token) {
    try {
        let response = await fetch(params().apiUri + url, {
            method: 'GET',
            mode: "cors",
            headers: {
                'Accept': 'application/x-www-form-urlencoded',
                'Origin': 'http://localhost:3000',
                'Content-Type': 'application/json',
                'x-access-token': token
            }
        });

        return response;
    } catch (error) {

    };
}

export const del = async function (url, token, body) {
    try {
        let response = await fetch(params().apiUri + url, {
            method: 'DELETE',
            mode: "cors",
            headers: {
                'Accept': 'application/x-www-form-urlencoded',
                'Origin': 'http://localhost:3000',
                'Content-Type': 'application/json',
                'x-access-token': token
            },
            body: JSON.stringify(body),
        });

        return response;
    } catch (error) {

    };
}
