import React, { useEffect, useRef, useState } from 'react'
import WebFont from 'webfontloader';
// eslint-disable-next-line
import { post, postBinary } from '../../controllers/endpoints.controller';
import { Routes, Route, Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import $ from 'jquery';
window.jQuery = $;
require('jquery.facedetection');


export default function Candidate() {

    const PAGE_TITLE = "Reverso";
    const [candidate, setCandidate] = useState("");
    const [showCandidate, setShowCandidate] = useState(true);
    const [playVideo, setPlayVideo] = useState(false);
    const [videoStarted, setVideoStarted] = useState(false);
    const [showSocial, setShowSocial] = useState(false);

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `${PAGE_TITLE}`;

        getParam()

    }, []);

    const getParam = function () {
        const params = new URLSearchParams(window.location.search);
        if (params.has('c')) {
            setCandidate(params.get('c'))

        } else {
            return -1;
        }
    }

    const handleStartVideo = async function () {
        setPlayVideo(true);
        analyticsSendPlayAction();
    }

    const handleVideoStarted = function () {
        setVideoStarted(true)
    }

    const handleEndVideo = function () {
        setVideoStarted(false)
        setShowSocial(true)
    }

    const playAgain = function () {
        setShowCandidate(false)
        setPlayVideo(true)
        setShowSocial(false)
        const v = document.getElementById("videoPhrase");
        v.currentTime = 0;
        v.play();
    }

    const shareSocial = (sn) => {
        const url = `https://campañacontraladesinformacion.reversoar.com/phrases/${candidate}_logo.mp4`

        const text = `Para evitar la desinformación electoral, mirá lo que este candidato tiene para contarte: https://desinformacion.reversoar.com/${candidate} y escaneá los rostros de otros candidatos acá: https://desinformacion.reversoar.com
        \n\nVotes a quien votes, que la desinformación quede fuera de las urnas.
        \n\nreversoar.com, información contra la desinformación.`

        const texttw = `Mirá lo que este candidato tiene para contarte: https://desinformacion.reversoar.com/${candidate} y escaneá los rostros de otros candidatos acá: https://desinformacion.reversoar.com\n\nVotes a quien votes, que la desinformación quede fuera de las urnas.\n\nInformate en reversoar.com`

        let encodedText;

        analyticsSendShareAction(sn);
        
        switch (sn) {
            case "download":
                const a = document.createElement('a');
                a.href = url;
                a.download = `Reverso - ${candidate.charAt(0).toUpperCase() + candidate.slice(1)}.mp4`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                break;
            case "x":
                encodedText = encodeURIComponent(texttw);
                const urlTwitter = `https://twitter.com/intent/tweet?text=${encodedText}`;
                window.open(urlTwitter, '_blank');
                break;
            case "whatsapp":
                encodedText = encodeURIComponent(text);
                const urlWhatsApp = `https://wa.me/?text=${encodedText}`;
                window.open(urlWhatsApp, '_blank');
                break;
            case "facebook":
                encodedText = encodeURIComponent(text);
                const urlFacebook = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(`https://desinformacion.reversoar.com/${candidate}`) + '&quote=' + encodeURIComponent(text);
                window.open(urlFacebook, '_blank');
                break;
        }
    }

    const analyticsSendPlayAction = async () => {
        const body = {
            candidate: candidate
        };

        await post(`/analytics/play`, null, body);
    }

    const analyticsSendShareAction = async (socialNetwork) => {
        const body = {
            candidate: candidate,
            socialNetwork: socialNetwork
        };

        await post(`/analytics/share`, null, body);
    }

    return (
        <div className='p-0 view'>

            <div className='background2' style={{ position: "absolute", width: '100%', height: "100%", top: 0, overflow: "hidden", zIndex: 6 }}>
                {showCandidate ?
                    <div className='candidateBackgroundBlack' style={{ position: "absolute", width: '100%', height: "100%", top: 0, overflow: "hidden", zIndex: 8 }}>
                        <div style={{ width: "100%", marginTop: "8em", textAlign: 'center' }}>
                            <img width="50%" src={`/faces/${candidate}.png`}></img>
                        </div>
                        <div style={{ width: "100%", marginTop: "6em", textAlign: 'center' }}>
                            <p><button className='buttonPrincipal' onClick={handleStartVideo}>HACELO HABLAR</button></p>

                        </div>

                    </div>
                    :
                    <div></div>
                }


                <div style={{ position: "absolute", width: '200px', height: "200px", top: 10, left: 20, overflow: "hidden", zIndex: 100 }}>
                    <img src='/logo.png' height={80}></img>
                </div>


                {playVideo ? (
                    <div>
                        <video
                            id="videoPhrase"
                            src={`/phrases/${candidate}.mp4`}
                            style={{ position: 'absolute', width: '100%', height: '100%', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', objectFit: 'cover', zIndex: 13 }}
                            autoPlay={true}
                            controls={false}
                            playsInline={true}
                            onPlay={handleVideoStarted}
                            onEnded={handleEndVideo}
                        />
                    </div>
                ) :
                    <div></div>
                }

                {showSocial ?
                    <div className='candidateBackground' style={{ position: "absolute", width: '100%', height: "100%", top: 0, overflow: "hidden", zIndex: 15 }}>
                 
                        <div style={{ width: "100%", marginTop: "8em", textAlign: 'center' }}>
                            <img width="30%" src={`/faces/${candidate}.png`}></img>
                        </div>
                        <div style={{ width: "100%", marginTop: "20px", textAlign: 'center' }}>
                            <p style={{ paddingLeft: 70, paddingRight: 70 }} className="textSlogan">
                                VOTES A QUIEN VOTES,<br></br>QUE LA DESINFORMACIÓN<br></br>QUEDE FUERA DE LAS URNAS
                            </p>
                        </div>
                        <div style={{ width: "100%", marginTop: "6em", textAlign: 'center' }}>
                            <p><button className='buttonPrincipal' onClick={playAgain}>REPETIR</button></p>
                            <p style={{ marginTop: 30 }} className="textSocial">
                                Compartí el video en tus redes sociales
                            </p>
                            <p style={{ marginTop: 10 }} className="textSocial">
                                <img onClick={() => shareSocial("facebook")} src="/facebook.png" width={30} />
                                <img onClick={() => shareSocial("whatsapp")} src="/whatsapp.png" width={30} style={{ marginLeft: 20 }} />
                                <img onClick={() => shareSocial("x")} src="/x.png" width={30} style={{ marginLeft: 20 }}/>
                                <img onClick={() => shareSocial("download")} src="/download.png" width={30} style={{ marginLeft: 20 }} />
                            </p>
                        </div>

                    </div>
                    :
                    <div></div>
                }
            </div>
        </div>

    );
}


