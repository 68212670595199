import {params} from '../tools/constants';

export const saveUserData = function (user){
    localStorage.setItem('userToken', user.token);
    localStorage.setItem('userName', user.name);
    localStorage.setItem('userSurname', user.surname);
    localStorage.setItem('userId', user.id);
}

export const validateUser = function (){
    const token = localStorage.getItem("userToken");
    if(token !== undefined && token !== null){
        if(token.length > 10){
            //Usuario está logueado
            if(window.location.href !== params.homePage){
                //Estoy en el inicio
                window.location.href = "/panel";
            }
        }else{
            window.location.href = "/login";
        }
    }else{
        window.location.href = "/login";
    }
}

export const getUserToken = function (){
    return localStorage.getItem('userToken');
}

export const getUserId = function (){
    return localStorage.getItem('userId');
}

export const logout = function (user){
    localStorage.removeItem('userToken');
    localStorage.removeItem('userName');
    localStorage.removeItem('userSurname');
    window.location.href = '/login';
}

export const forceLogout = function (user){
    localStorage.removeItem('userToken');
    localStorage.removeItem('userName');
    localStorage.removeItem('userSurname');
    window.location.href = '/login';
}