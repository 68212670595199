import React, { useEffect } from 'react'
// eslint-disable-next-line
import { Routes, Route, Link } from "react-router-dom";
import { Container, Dropdown, Nav, Badge, Navbar, Row, Col, Button } from "react-bootstrap";

export default function NavBar() {

    useEffect(() => {

    }, []);

    return (
        <div style={{ marginBottom: -10 }}>
            <Navbar fixed="top" className="pb-2" style={{ background: "white", borderBottom: "2px solid #00ff00" }}>
                <Container>
                    <Navbar.Brand style={{textAlign: 'center'}}>
                        <img
                            src="/banner.png"
                            style={{width: '70%'}}
                            className="d-inline-block align-top"
                            alt="Reverso"
                        />
                    </Navbar.Brand>
                    
                </Container>
            </Navbar>
        </div>
    )
}