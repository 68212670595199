import React, { useEffect, useRef, useState } from 'react'
import WebFont from 'webfontloader';
// eslint-disable-next-line
import { post, postBinary } from '../../controllers/endpoints.controller';
import { Routes, Route, Link } from "react-router-dom";

import $ from 'jquery';
window.jQuery = $;
require('jquery.facedetection');


export default function Scan() {

    const PAGE_TITLE = "Reverso";
    let video;
    let canvas;
    let canvasFace;
    const [showLoading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [showEnableCamera, setShowEnableCamera] = useState(true);
    const [showBackground1, setShowBackground1] = useState(true);
    const [canSend, setCanSend] = useState(true);
    const [showCandidate, setShowCandidate] = useState(false);
    const [playVideo, setPlayVideo] = useState(false);
    const [videoStarted, setVideoStarted] = useState(false);
    const [showSocial, setShowSocial] = useState(false);
    const [detectedCandidate, setDetectedCandidate] = useState("");
    const [showIndicator, setShowIndicator] = useState(false);
    const [showFaceIndicator, setShowFaceIndicator] = useState(false);
    const [d, setD] = useState(null);
    const [showLegal, setShowLegal] = useState(false);
    let intervalo = null;
    let initialized = false;
    let context;
    let contextFace;
    let stream = null;

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `${PAGE_TITLE}`;



        getData()

    }, []);

    const showFaceAnimation = (x, y, w, h) => {
        setShowFaceIndicator(true)
    }

    const clearFaceAnimation = () => {
        setShowFaceIndicator(false)
    }

    const getData = async () => {
        video = document.getElementById("video");
        canvas = document.getElementById('canvas');
        canvasFace = document.getElementById('canvasFace');

        canvas.height = window.innerHeight
        canvas.width = window.innerWidth

        canvasFace.height = window.innerHeight
        canvasFace.width = window.innerWidth

        context = canvas.getContext('2d');
        contextFace = canvasFace.getContext('2d');

        setTimeout(startWebcam, 2000);

        const playEventHandler = async () => {
            if (intervalo) {
                clearInterval(intervalo)
            }

            let reps = 0;
            let attempts = 0;

            intervalo = setInterval(async () => {
                reps++;
                setLoaded(true)
                if (canSend) {
                    setCanSend(false)

                    context.drawImage(video, 0, 0, canvas.width, canvas.height);

                    canvas.toBlob(async function (blob) {
                        const objectURL = URL.createObjectURL(blob);
                        const imgElement = document.getElementById("imgFace");
                        imgElement.src = objectURL;

                        imgElement.onload = function () {
                            $('#imgFace').faceDetection({
                                complete: function (faces) {
                                    console.log(attempts)
                                    if (faces.length > 0) {
                                        attempts = 0;
                                        for (let f of faces) {
                                            showFaceAnimation(f.x, f.y, f.width, f.height)
                                        }
                                        URL.revokeObjectURL(objectURL);
                                    } else {
                                        clearFaceAnimation();
                                    }

                                }
                            });
                        }

                        if ((reps * 200) > 1200) {

                            reps = 0;
                            processImage(blob);
                        }
                    }, 'image/jpeg');
                }
            }, 200);
        };

        if (video != null) {
            video.addEventListener("play", playEventHandler);
        }
    }

    const startWebcam = () => {
        navigator.mediaDevices
            .getUserMedia({
                video: {
                    facingMode: 'environment',
                },
                audio: false,
            })
            .then((s) => {
                stream = s;
                setTimeout(hideIntro(), 2000);
                video.srcObject = stream;
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const hideIntro = () => {
        setShowBackground1(false)
        setShowEnableCamera(false)
        setShowIndicator(true)
    }

    const stopWebcam = () => {
        if (stream) {
            const tracks = stream.getTracks();
            tracks.forEach(track => track.stop());
            video.srcObject = null;
        }
    }

    const processImage = async function (data) {
        const formData = new FormData();
        formData.append('image', data, 'captured-image.jpg');

        const req = await postBinary(`/candidate/recognize`, null, formData);
        const res = await req.json();

        if (req.status === 200) {
            switch (res.result) {
                case "milei":
                    processCandidate('milei')
                    break;
                case "massa":
                    processCandidate('massa')
                    break;
                case "bullrich":
                    processCandidate('bullrich')
                    break;
            }

            setCanSend(true);

            return true;

        } else {
            alert(res.message);
        }
    }

    const processCandidate = (slug) => {
        setDetectedCandidate(slug)
        stopWebcam();
        document.getElementById('video').remove();
        clearInterval(intervalo)
        setShowCandidate(true)
    }

    const handleStartVideo = async function () {
        setPlayVideo(true);
        analyticsSendPlayAction();
    }

    const handleVideoStarted = function () {
        setShowIndicator(false)
        setVideoStarted(true)
        setShowLegal(false)
    }

    const handleEndVideo = function () {
        setVideoStarted(false)
        setShowSocial(true)
        setShowLegal(true)
    }

    const playAgain = function () {
        setShowCandidate(false)
        setPlayVideo(true)
        setShowSocial(false)
        const v = document.getElementById("videoPhrase");
        v.currentTime = 0;
        v.play();
    }

    const rescan = function () {
        window.location.reload();
    }

    const shareSocial = (sn) => {
        const url = `https://campañacontraladesinformacion.reversoar.com/phrases/${detectedCandidate}_logo.mp4`

        const text = `Para evitar la desinformación electoral, mirá lo que este candidato tiene para contarte: https://desinformacion.reversoar.com/${detectedCandidate} y escaneá los rostros de otros candidatos acá: https://desinformacion.reversoar.com
        \n\nVotes a quien votes, que la desinformación quede fuera de las urnas.
        \n\nreversoar.com, información contra la desinformación.`

        const texttw = `Mirá lo que este candidato tiene para contarte: https://desinformacion.reversoar.com/${detectedCandidate} y escaneá los rostros de otros candidatos acá: https://desinformacion.reversoar.com\n\nVotes a quien votes, que la desinformación quede fuera de las urnas.\n\nInformate en reversoar.com`

        let encodedText;

        analyticsSendShareAction(sn)

        switch (sn) {
            case "download":
                const a = document.createElement('a');
                a.href = url;
                a.download = `Reverso - ${detectedCandidate.charAt(0).toUpperCase() + detectedCandidate.slice(1)}.mp4`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                break;
            case "x":
                encodedText = encodeURIComponent(texttw);
                const urlTwitter = `https://twitter.com/intent/tweet?text=${encodedText}`;
                window.open(urlTwitter, '_blank');
                break;
            case "whatsapp":
                encodedText = encodeURIComponent(text);
                const urlWhatsApp = `https://wa.me/?text=${encodedText}`;
                window.open(urlWhatsApp, '_blank');
                break;
            case "facebook":
                encodedText = encodeURIComponent(text);
                const urlFacebook = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(`https://desinformacion.reversoar.com/${detectedCandidate}`) + '&quote=' + encodeURIComponent(text);
                window.open(urlFacebook, '_blank');
                break;
        }
    }

    const analyticsSendPlayAction = async () => {
        const body = {
            candidate: detectedCandidate
        };

        await post(`/analytics/play`, null, body);
    }

    const analyticsSendShareAction = async (socialNetwork) => {
        const body = {
            candidate: detectedCandidate,
            socialNetwork: socialNetwork
        };

        await post(`/analytics/share`, null, body);
    }

    return (
        <div className='p-0 view'>


            <div>
                <video id="video" style={{ position: 'absolute', width: "100%", height: "100vh", top: '50%', left: '50%', zIndex: 1, transform: 'translate(-50%, -50%)', objectFit: 'cover' }} autoPlay={true} playsInline={true} muted={true} />

                <canvas id="canvas" style={{ position: 'absolute', width: "100%" }} ></canvas>

                <canvas id="canvasFace" style={{ position: 'absolute', width: "100%", height: "100%", zIndex: 3, left: 0 }}></canvas>

                <img id="imgFace" style={{ position: 'absolute', width: "100%", height: "100%" }} />

                {showIndicator ?
                    <div className='' style={{ position: "absolute", width: '100%', height: "100%", top: 0, overflow: "hidden", zIndex: 5 }}>
                        <div style={{ width: "100%", marginTop: "8em", textAlign: 'center' }}>
                            <img width="50%" src={`/faces/indicator.png`}></img>
                        </div>
                        <div style={{ width: "100%", marginTop: 20, textAlign: 'center' }}>
                            <p className='textIndicator'>Enfocá el rostro de un candidato</p>
                        </div>
                    </div>
                    :
                    <div></div>
                }

                {showFaceIndicator ?
                    <div className='' style={{ position: "absolute", width: '100%', height: "100%", top: 0, overflow: "hidden", zIndex: 6 }}>
                        <div style={{ width: "100%", marginTop: "8em", textAlign: 'center' }}>
                            <img width="50%" src={`/face_animation.gif`}></img>
                        </div>
                    </div>
                    :
                    <div></div>
                }

                {showBackground1 ?
                    <div className='' style={{ height: "100vh", width: "100%", zIndex: 200 }}>

                        <div className='background1' style={{ position: "absolute", width: '100%', height: "100%", top: 0, overflow: "hidden", zIndex: 6 }}></div>


                        <div style={{ position: "absolute", width: 'calc(100% - 80px)', height: 130, bottom: 40, left: 40, overflow: "hidden", zIndex: 7 }}>


                            {showEnableCamera ?
                                <div className='loadingBar' style={{ height: 58 }} >
                                    <div id='textCamera' style={{ width: "60%", background: "black" }}>
                                        <p>Activá la cámara</p>
                                        <p style={{ marginTop: -16 }}>y hacé hablar la cara</p>
                                        <p style={{ marginTop: -16 }}>del político</p>
                                    </div>


                                </div>
                                :
                                <div></div>
                            }

                        </div>
                    </div>
                    :
                    <div></div>
                }

                {showCandidate ?
                    <div className='candidateBackground' style={{ position: "absolute", width: '100%', height: "100%", top: 0, overflow: "hidden", zIndex: 8 }}>
                        <div style={{ width: "100%", marginTop: "8em", textAlign: 'center' }}>
                            <img width="50%" src={`/faces/${detectedCandidate}.png`}></img>
                        </div>
                        <div style={{ width: "100%", marginTop: "6em", textAlign: 'center' }}>
                            <p><button className='buttonPrincipal' onClick={handleStartVideo}>HACELO HABLAR</button></p>
                            <p style={{ marginTop: 10 }}>
                                <button className='buttonSecondary' onClick={rescan}>VOLVÉ A ESCANEAR</button>
                            </p>
                        </div>

                    </div>
                    :
                    <div></div>
                }


                <div style={{ position: "absolute", width: '200px', height: "200px", top: 10, left: 20, overflow: "hidden", zIndex: 100 }}>
                    <img src='/logo.png' height={80}></img>
                </div>


                {playVideo ? (
                    <div>
                        <video
                            id="videoPhrase"
                            src={`/phrases/${detectedCandidate}.mp4`}
                            style={{ position: 'absolute', width: '100%', height: '100%', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', objectFit: 'cover', zIndex: 13 }}
                            autoPlay={true}
                            controls={false}
                            playsInline={true}
                            onPlay={handleVideoStarted}
                            onEnded={handleEndVideo}
                        />



                    </div>
                ) :
                    <div></div>
                }

                {showSocial ?
                    <div className='candidateBackground' style={{ position: "absolute", width: '100%', height: "100%", top: 0, overflow: "hidden", zIndex: 15 }}>
                        <div style={{ width: "100%", marginTop: "7em", textAlign: 'center' }}>
                            <img width="30%" src={`/faces/${detectedCandidate}.png`}></img>
                        </div>
                        <div style={{ width: "100%", marginTop: "20px", textAlign: 'center' }}>
                            <p style={{ paddingLeft: 50, paddingRight: 50 }} className="textSlogan">
                                VOTES A QUIEN VOTES,<br></br>QUE LA DESINFORMACIÓN<br></br>QUEDE FUERA DE LAS URNAS
                            </p>
                        </div>

                        <div style={{ width: "100%", marginTop: "2em", textAlign: 'center' }}>
                            <p><button className='buttonPrincipal' onClick={playAgain}>REPETIR</button></p>
                            <p style={{ marginTop: 10 }}>
                                <button className='buttonSecondary' onClick={rescan}>VOLVÉ A ESCANEAR</button>
                            </p>
                            <p style={{ marginTop: 15 }} className="textSocial">
                                Compartí el video en tus redes sociales
                            </p>
                            <p style={{ marginTop: 10 }} className="textSocial">
                                <img onClick={() => shareSocial("facebook")} src="/facebook.png" width={30} />
                                <img onClick={() => shareSocial("whatsapp")} src="/whatsapp.png" width={30} style={{ marginLeft: 20 }} />
                                <img onClick={() => shareSocial("x")} src="/x.png" width={30} style={{ marginLeft: 20 }} />
                                <img onClick={() => shareSocial("download")} src="/download.png" width={30} style={{ marginLeft: 20 }} />
                            </p>
                        </div>

                    </div>
                    :
                    <div></div>
                }

                {showLegal ?
                    <div style={{ position: "absolute", width: 'calc(100% - 40px)', height: 30, bottom: 10, left: 20, overflow: "hidden", zIndex: 20, textAlign: 'center', color: 'white' }}>
                        <p className='textLegal'>Las voces y personas de este video no son reales y fueron generadas con inteligencia artificial.</p>
                    </div>
                    :
                    <div></div>}


            </div>



        </div>

    );
}


