import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Col, Card, Badge, Button, Form, Modal, Spinner } from "react-bootstrap";
import moment from 'moment'
import { params } from '../../tools/constants'
import { get, post, del, put } from '../../controllers/endpoints.controller';
import { getUserToken, logout, getUserRoleParams } from '../../controllers/user.controller';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faSmile, faUser } from '@fortawesome/free-regular-svg-icons'
import { faBell, faCheck, faCoins, faDownload, faEllipsisV, faEye, faFileExport, faFileImport, faGavel, faIndustry, faPlusCircle, faStore, faTasks, faTrash, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import NavBar from "./Navbar";


export default function Home() {

    const [candidates, setCandidates] = React.useState([]);

    const [showLoading, setShowLoading] = React.useState(false);
    const handleCloseLoadingModal = () => { setShowLoading(false); };
    const handleShowLoadingModal = () => {
        setShowLoading(true);
    };

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto']
            }
        });

        document.title = "Reverso - Inicio";
        getCandidates()
    }, []);

    const getCandidates = async function () {
        const req = await get('/candidate/all', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setCandidates(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const editCandidateVideo = async function (candidateId, phrases) {
        handleShowLoadingModal()
        let result = [];
        let aux = {};

        for (let p of phrases) {
            aux = {
                id: p.id
            }

            if (document.getElementById(`candidate-${candidateId}-${p.id}`).checked == true) {
                aux.show = true;
            } else {
                aux.show = false;
            }

            aux.order = document.getElementById(`order-${candidateId}-${p.id}`).value

            result.push(aux)
        }

        console.log(result)

        const body = {
            candidateId: candidateId,
            phrases: result
        }

        console.log(body)

        const req = await put('/candidate/update', getUserToken(), body);
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            handleCloseLoadingModal()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    return (
        <div className='p-0 mt-3' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <Container style={{ marginTop: 150 }} className='mb-5'>
                {candidates.map((c, i) => (
                    <Card className='mt-2'>
                        <Container className='pt-3 pb-3'>
                            <p>{c.name}</p>
                            {c.Phrases.map((p, i) => (
                                <Card className='mt-1'>
                                    <Container>
                                        <Row>
                                            <Col xs={1} className='align-center-vertically'>
                                                <Form.Check
                                                    type='checkbox'

                                                    id={`candidate-${c.id}-${p.id}`}
                                                    defaultChecked={p.show}
                                                />
                                            </Col>
                                            <Col xs={1} className='align-center-vertically'>
                                                <Form.Control type="text" id={`order-${c.id}-${p.id}`} placeholder="" defaultValue={p.order} />
                                            </Col>
                                            <Col xs={10}>
                                                <p className='mt-1 mb-1'>{p.description}</p>
                                            </Col>
                                        </Row>


                                    </Container>

                                </Card>
                            ))}
                            <div className='mt-3'>
                                <Button onClick={() => editCandidateVideo(c.id, c.Phrases)}>Aplicar</Button>
                            </div>
                        </Container>

                    </Card>
                ))}

            </Container>

            <Modal show={showLoading} backdrop="static" keyboard={false} onHide={handleCloseLoadingModal}>
                <Modal.Body style={{ textAlign: 'center' }}>

                    <Spinner className='mt-3' animation="border" />
                    <p className='mt-1 mb-3'>Aplicando cambios. Este proceso puede durar aprox. 5 minutos.</p>


                </Modal.Body>
            </Modal>
        </div>
    )
}