import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import './App.css';

import Navbar from "./components/ui/Navbar";
import Scan from "./components/ui/Scanv2";
import Login from "./components/ui/Login";
import Panel from "./components/ui/Panel";
import Candidate from "./components/ui/Candidate";

export function App() {
    return (
        <div>
            <Router>
                <Row className="m-0">
                    <Routes>
                        <Route path="/" element={<Scan />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/panel" element={<Panel />} />
                        <Route path="/c" element={<Candidate />} />
                    </Routes>
                </Row>
            </Router>
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossOrigin="anonymous" />
        </div>
    );
}